
//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px;

// Small screen / tablet
$screen-sm:                  768px;

// Medium screen / desktop
$screen-md:                  992px;

// Large screen / wide desktop
$screen-lg:                  1200px;



//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              24;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         5px;

$grid-gutter-width-wide:    20px;

//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet:             (720px + $grid-gutter-width);
//** For `$screen-sm-min` and up.
$container-sm:                 $container-tablet;

// Medium screen / desktop
$container-desktop:            (940px + $grid-gutter-width);
//** For `$screen-md-min` and up.
$container-md:                 $container-desktop;

// Large screen / wide desktop
$container-large-desktop:      (1140px + $grid-gutter-width);
//** For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop;
