@font-face {
    font-family: 'aleoregular';
    src: url('#{$font-path}/Aleo-Regular-webfont.eot');
    src: url('#{$font-path}/Aleo-Regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/Aleo-Regular-webfont.woff') format('woff'),
    url('#{$font-path}/Aleo-Regular-webfont.ttf') format('truetype'),
    url('#{$font-path}/Aleo-Regular-webfont.svg#aleoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'aleoitalic';
    src: url('#{$font-path}/Aleo-Italic-webfont.eot');
    src: url('#{$font-path}/Aleo-Italic-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/Aleo-Italic-webfont.woff') format('woff'),
    url('#{$font-path}/Aleo-Italic-webfont.ttf') format('truetype'),
    url('#{$font-path}/Aleo-Italic-webfont.svg#aleoitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'aleolightitalic';
    src: url('#{$font-path}/Aleo-LightItalic-webfont.eot');
    src: url('#{$font-path}/Aleo-LightItalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/Aleo-LightItalic-webfont.woff') format('woff'),
    url('#{$font-path}/Aleo-LightItalic-webfont.ttf') format('truetype'),
    url('#{$font-path}/Aleo-LightItalic-webfont.svg#aleolightitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'aleolight';
    src: url('#{$font-path}/Aleo-Light-webfont.eot');
    src: url('#{$font-path}/Aleo-Light-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/Aleo-Light-webfont.woff') format('woff'),
    url('#{$font-path}/Aleo-Light-webfont.ttf') format('truetype'),
    url('#{$font-path}/Aleo-Light-webfont.svg#aleolight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'aleobolditalic';
    src: url('#{$font-path}/Aleo-BoldItalic-webfont.eot');
    src: url('#{$font-path}/Aleo-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/Aleo-BoldItalic-webfont.woff') format('woff'),
    url('#{$font-path}/Aleo-BoldItalic-webfont.ttf') format('truetype'),
    url('#{$font-path}/Aleo-BoldItalic-webfont.svg#aleobolditalic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'aleobold';
    src: url('#{$font-path}/Aleo-Bold-webfont.eot');
    src: url('#{$font-path}/Aleo-Bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/Aleo-Bold-webfont.woff') format('woff'),
    url('#{$font-path}/Aleo-Bold-webfont.ttf') format('truetype'),
    url('#{$font-path}/Aleo-Bold-webfont.svg#aleobold') format('svg');
    font-weight: normal;
    font-style: normal;

}
