.newsletter-signup {

  &__container {
    @include make-md-column(16);
    @include make-md-column-offset(4);

    @media (min-width: $screen-sm-min) {
      margin-top: 0px;
      margin-bottom: 0px
    }

  }

  &__title {
    @include h4;
    text-align: center;
    margin-top: 0;
    line-height: 0.9rem !important;
  }

  &__quote {
    @include sans-serif-body;
    text-align: center;
    margin-bottom: 1.5em;
  }

  .nam-newsletter-signup-form {
    @include make-row();

    label {
      display: none;
    }
    input {
      &.form-text,
      &.form-email {
        background: rgba(255, 255, 255, 0.1);
        border: none;
        border-bottom: 1px solid $color-nam-white;
        border-radius: 0;
        @include font-akkurat-pro-bold;
      }
      &:focus {
        outline: none !important;
      }

      &.form-text {
        color: white;
      }
      &.form-email {
        color: white;
      }
      &.form-submit {
        height: 44px;
        @include button-variant($color-nam-white, $color-nam-base-black, $color-nam-white);
        background-color: rgba(51, 51, 51, 0.2);
        border: 1px solid $color-nam-white;
        border-radius: 2px;
        text-transform: uppercase;
        @include font-akkurat-pro-bold;
        &:hover,
        &:focus,
        &.focus {
          background-color: rgba(51, 51, 51, 0.5);
          border: 1px solid $color-nam-white;
          text-decoration: none;
        }
        //@include make-sm-column(4);
        width: 100%;
        max-width: 500px;
      }
    }
    .form-item {
      @include make-sm-column(10);
      @include make-sm-column-offset(5);
      text-align: center;
      margin-right: 10px;
    }
    .form-item-submit {
      @include make-sm-column(4);
      text-align: center;
    }
  }

  ::-webkit-input-placeholder {
    color: $color-nam-white;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: $color-nam-white;
  }
  ::-moz-placeholder {  /* Firefox 19+ */
    color: $color-nam-white;
  }
  :-ms-input-placeholder {
    color: $color-nam-white;
  }
}


