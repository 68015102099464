.timeline-item {
  @include make-sm-column(24);

  @media (max-width: $screen-xs-max) {
    margin-bottom: 20px;
  }

  &__container {
    @include make-row();
  }

  &__position {
    &.left {
      text-align: right;
      @include make-sm-column(11);
      @include make-sm-column-offset(0);
      @include make-md-column(7);
      @include make-md-column-offset(4);

      @media (max-width: $screen-xs-max) {
        text-align: left;
      }
    }
    &.right {
      text-align: left;
      @include make-sm-column(11);
      @include make-sm-column-offset(13);
      @include make-md-column(7);
      @include make-md-column-offset(13);
    }
  }

  &__date {
    @include h3;
    @include accent-colour($applyTo: text);
    margin-bottom: 0px;
    margin-top: 0px;
  }

  &__title {
    @include h4;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  &__content {
    margin-bottom: 0px;
    margin-top: 10px;
  }

}
