.btn {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, $btn-border-radius-base);
  @include user-select(none);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:hover,
  &:focus,
  &.focus {
    color: $btn-default-color;
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    @include opacity(.65);
    @include box-shadow(none);
  }

  // [converter] extracted a& to a.btn
}

a.btn {
  &.disabled,
  fieldset[disabled] & {
    pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
  }
}


// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  color: $link-color;
  font-weight: normal;
  border-radius: 0;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    @include box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: $btn-link-disabled-color;
      text-decoration: none;
    }
  }
}


// Block button
// --------------------------------------------------

.btn-block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}


//-------------



.btn {

  @include button-variant($color-nam-white, $color-nam-primary-green, $color-nam-primary-green);

  @include font-akkurat-pro-bold;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  border-radius: 2px;
  padding: 8px 11px 6px 11px;
  @media (max-width: $screen-xs-max) {
    padding: 10px 11px 8px;
  }

  &.small {
    font-size: 12px;
    padding: 7px 9px 5px 9px;
  }

  &.large {
    @media (max-width: $screen-xs-max) {
      font-size: 15px;
      padding: 10px 12px 10px;
    }
    font-size: 16px;
    padding: 12px 15px 10px 15px;
  }

  &.primary {
    @include button-variant($color-nam-white, $color-nam-base-black, $color-nam-white);

    background-color: rgba(51, 51, 51, 0.2);

    &:hover,
    &:focus,
    &.focus {
      background-color: rgba(51, 51, 51, 0.5);
      border: 1px solid $color-nam-white;
      text-decoration: none;
    }
  }

  &.variable {
    @include button-variant($color-nam-white, $color-nam-bright-pink, $color-nam-bright-pink);;
  }

  &__arrow {
    font-size: 85%;
    margin-left: 5px;
  }

}

//.btn__arrow {
//  font-size: 12px;
//  margin-left: 3px;
//}

@mixin primary-btn-style {
  @include button-variant($color-nam-white, $color-nam-base-black, $color-nam-white);

  background-color: rgba(51, 51, 51, 0.2);

  &:hover,
  &:focus,
  &.focus {
    background-color: rgba(51, 51, 51, 0.5);
    border: 1px solid $color-nam-white;
    text-decoration: none;
  }
}
