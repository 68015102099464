.card-listing {
  @include make-sm-column(8, $grid-gutter-width-wide);
  @include make-lg-column(6, $grid-gutter-width-wide);
  margin-bottom: 20px;

  &__content {
    color: $color-nam-base-black;
    background-color: $color-nam-off-white;
  }

  &__image {
    //4:3 ratio
    width: 100%;
  }

  &__content-container {
    padding: 0 15px 15px 15px;
  }

  &.last-card {
    @media (max-width: $screen-md-max) {
      display: none;
    }
    @media (min-width: $screen-lg-min) {
      display: block;
    }
  }

}

.listing-block {
  &.whats-on {
    .card-listing {
      @include make-sm-column(8, $grid-gutter-width-wide);
      @include make-lg-column(6, $grid-gutter-width-wide);
    }
  }
  .card-listing {
    &.related-links {
      @include make-sm-column(8, $grid-gutter-width-wide);
    }
  }
}

