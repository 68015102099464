.card-multi-item-primary {
  @include make-sm-column(11);
  @include make-md-column(11);
  @include make-lg-column(10);

  color: $color-nam-base-black;
  background-color: $color-nam-off-white;
  padding: 0px 0px 15px 0px;

  margin-bottom: 10px;

  @media (min-width: $screen-sm-min) {
    padding: 0px 15px 15px 15px;
    margin-bottom: 0px;
  }

}

.card-multi-item-primary {
  .item-info-feature {
    border-top: 1px solid $color-nam-mid-grey;

    &__label {
      margin-top: -1px;
    }

  }
}

.card-multi-item-primary {
  &__title {
    @include h2;
  }
}



