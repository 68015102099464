.tabs.primary {
  background: $color-nam-base-black;
  margin: 0;

  li {
    display: inline-block;
    a {
      color: $color-nam-white;
      padding: 10px 20px;
      display: block;
      &:hover {
        background: rgba($color-nam-white, 0.1);
        text-decoration: none;
      }
    }
    &.is-active {
      background: $color-nam-white;
      a {
        color: $color-nam-base-black;
      }
    }
  }
}

.block-system-main-block {
  p:empty {
    display: none;
  }
}
