/*
  Manage z-index stack here
*/

$vars_z-index: (
    overlay: 3,
    header: 2,
    mobile-header: 1
);

.header {
   &__nam-brand {
     z-index: 3;
   }
  .navbar-default {
    z-index: 2;
  }
}

.social-sharing {
  z-index: 4;
}

.social-container {
  z-index: 5;
}
