.nam-ckeditor-video {
  position: relative;

  figure {
    width: 100%;
  }

  &__image {
    width: 100%;
  }

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  &__container {
    position: relative;
    /* 30 (modal-body padding) + 30 (title height) + 30 (title padding) + 1 (title border) + 2 (modal border) + 30 (modal padding) */
    max-height: calc(100vh - 123px);
    overflow: hidden;
  }

  &__play-button {
    position: absolute;
    left: 50%;
    top: 50%;
    /* Half of button width + padding + border. */
    margin-left: -(100px / 2 + 10px + 2px) ;
    /* Half of button height + padding + border. */
    margin-top: -(100px / 2 + 10px + 2px) !important;
    padding: 10px;
    background: rgba(51,51,51,0.9);
    border: solid 2px #FFFFFF;
    border-radius: 50%;
    color: #FFFFFF;
    font-family: 'Akkurat-Pro-Bold', sans-serif;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;

    img {
      margin-bottom: 10px;
      margin-left: 4px;
    }

    div {
      width: 100px;
      height: 100px;
      display: table-cell;
      vertical-align: middle;
    }

    span {
      display: block;
    }

    &:hover {
      background: black;
      cursor: pointer;
    }

  }

  &__iframe-wrapper {
    iframe {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
    }
    &:after {
      content: '';
      display: block;
      padding-bottom: 56.25%;
    }
  }

}

.nam-ckeditor-video-modal {
  padding: 15px !important;

  &__title {
    font-size: 1rem;
    color: #333333;
    margin: 0 !important;
  }
}
