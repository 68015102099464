@mixin item-label {
  display: inline-block;

  padding: 10px;
  background-color: $color-nam-beige;

  @include font-akkurat-pro-bold;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 1;
  text-transform: uppercase;
  color: $color-nam-base-black;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}
