.paragraph-media-1col {
  @include make-md-column(20);
  @include make-md-column-offset(2);
  @include make-lg-column(16);
  @include make-lg-column-offset(4);

  margin-bottom: 50px;
  @media (max-width: $screen-xs-max) {
    margin-bottom: 30px;
  }

}

//styling for paragraph use on 2 col templates -event / resource etc
.twocol-template {
  .paragraph-media-1col {
    @include make-sm-column(24);
    @include make-sm-column-offset(0);

    @media (max-width: $screen-xs-max) {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}

