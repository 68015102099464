label {
   @include sans-serif-body;
   @include font-akkurat-pro-bold;
 }

.form-item {
  label {
    display: block;
  }
  input {
    width: 100%;
    max-width: 500px;

    padding: 0px 10px;
    border-radius: 2px;
    height: 44px;
    background-image: none !important;
    border: solid 1px $color-nam-dark-grey;
    &:focus {
      outline: none !important;
    }
  }
  select {
    width: 100%;
    max-width: 500px;
    height: 44px;
    padding: 5px 10px;
    border-radius: 2px;
    border: 1px solid $color-nam-dark-grey;
    &:focus {
      outline: none !important;
    }
  }
  textarea {
    width: 100%;
    max-width: 500px;
    padding: 5px 10px;
    border-radius: 2px;
    border: 1px solid $color-nam-dark-grey;
    &:focus {
      outline: none !important;
    }
  }
}

.webform-button--submit {
  @include button-variant($color-nam-white, $color-nam-primary-green, $color-nam-primary-green);

  @include font-akkurat-pro-bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  border-radius: 2px;
  border-width: 1px;
  border-style: solid;

  @media (max-width: $screen-xs-max) {
    font-size: 15px;
    padding: 10px 12px 10px;
  }
  font-size: 16px;
  padding: 12px 15px 10px 15px;

 }



