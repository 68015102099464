@font-face {
  font-family: 'Linearicons';
  src:  url('#{$font-path}/Linearicons.eot?kdakas');
  src:  url('#{$font-path}/Linearicons.eot?kdakas#iefix') format('embedded-opentype'),
  url('#{$font-path}/Linearicons.woff2?kdakas') format('woff2'),
  url('#{$font-path}/Linearicons.ttf?kdakas') format('truetype'),
  url('#{$font-path}/Linearicons.woff?kdakas') format('woff'),
  url('#{$font-path}/Linearicons.svg?kdakas#Linearicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Linearicons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  /*letter-spacing: 0;*/
  /*-webkit-font-feature-settings: "liga";*/
  /*-moz-font-feature-settings: "liga=1";*/
  /*-moz-font-feature-settings: "liga";*/
  /*-ms-font-feature-settings: "liga" 1;*/
  /*font-feature-settings: "liga";*/
  /*-webkit-font-variant-ligatures: discretionary-ligatures;*/
  /*font-variant-ligatures: discretionary-ligatures;*/

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close-arrow:before {
  content: "\e905";
}

.icon-caption-info:before {
  content: "\e904";
}

.icon-dot-small:before {
  content: "\e900";
}
.icon-dot-large:before {
  content: "\e901";
}
.icon-arrow-solid:before {
  content: "\e902";
}
.icon-home3:before {
  content: "\e602";
}
.icon-star:before {
  content: "\e68d";
}
.icon-envelope:before {
  content: "\e696";
}
.icon-paperclip:before {
  content: "\e698";
}
.icon-printer:before {
  content: "\e6b1";
}
.icon-file-empty:before {
  content: "\e6b3";
}
.icon-file-add:before {
  content: "\e6b4";
}
.icon-file-check:before {
  content: "\e6b5";
}
.icon-file-lock:before {
  content: "\e6b6";
}
.icon-music-note2:before {
  content: "\e6e5";
}
.icon-headphones:before {
  content: "\e6ef";
}
.icon-equalizer:before {
  content: "\e6f2";
}
.icon-ticket:before {
  content: "\e6f5";
}
.icon-film-play:before {
  content: "\e6f8";
}
.icon-camera-play:before {
  content: "\e701";
}
.icon-picture:before {
  content: "\e70e";
}
.icon-picture3:before {
  content: "\e710";
}
.icon-pictures:before {
  content: "\e711";
}
.icon-book:before {
  content: "\e712";
}
.icon-user:before {
  content: "\e71e";
}
.icon-users2:before {
  content: "\e723";
}
.icon-man-woman:before {
  content: "\e733";
}
.icon-map-marker:before {
  content: "\e77a";
}
.icon-calendar-31:before {
  content: "\e788";
}
.icon-calendar-full:before {
  content: "\e789";
}
.icon-bubbles:before {
  content: "\e7d7";
}
.icon-car:before {
  content: "\e84a";
}
.icon-car2:before {
  content: "\e84f";
}
.icon-train:before {
  content: "\e85a";
}
.icon-bicycle:before {
  content: "\e85f";
}
.icon-smile:before {
  content: "\e88a";
}
.icon-mic:before {
  content: "\e8cb";
}
.icon-clock3:before {
  content: "\e8e8";
}
.icon-enter-down2:before {
  content: "\e903";
}
.icon-file-code:before {
  content: "\e90c";
}
.icon-file-image:before {
  content: "\e90d";
}
.icon-file-zip:before {
  content: "\e90e";
}
.icon-file-audio:before {
  content: "\e90f";
}
.icon-file-video:before {
  content: "\e910";
}
.icon-file-preview:before {
  content: "\e911";
}
.icon-file-charts:before {
  content: "\e912";
}
.icon-file-stats:before {
  content: "\e913";
}
.icon-file-spreadsheet:before {
  content: "\e914";
}
.icon-thumbs-up:before {
  content: "\e919";
}
.icon-thumbs-down:before {
  content: "\e91a";
}
.icon-thumbs-up2:before {
  content: "\e91b";
}
.icon-thumbs-down2:before {
  content: "\e91c";
}
.icon-share2:before {
  content: "\e920";
}
.icon-magnifier:before {
  content: "\e922";
}
.icon-zoom-in:before {
  content: "\e925";
}
.icon-zoom-out:before {
  content: "\e926";
}
.icon-cross:before {
  content: "\e92a";
}
.icon-menu:before {
  content: "\e92b";
}
.icon-menu3:before {
  content: "\e931";
}
.icon-check:before {
  content: "\e934";
}
.icon-cross2:before {
  content: "\e935";
}
.icon-chevron-up:before {
  content: "\e939";
}
.icon-chevron-down:before {
  content: "\e93a";
}
.icon-chevron-left:before {
  content: "\e93b";
}
.icon-chevron-right:before {
  content: "\e93c";
}
.icon-arrow-left:before {
  content: "\e943";
}
.icon-arrow-right:before {
  content: "\e944";
}
.icon-expand:before {
  content: "\e94a";
}
.icon-contract:before {
  content: "\e94b";
}
.icon-expand2:before {
  content: "\e94c";
}
.icon-contract2:before {
  content: "\e94d";
}
.icon-warning:before {
  content: "\e955";
}
.icon-notification-circle:before {
  content: "\e956";
}
.icon-checkmark-circle:before {
  content: "\e959";
}
.icon-cross-circle:before {
  content: "\e95a";
}
.icon-chevron-right-circle:before {
  content: "\e965";
}
.icon-play-circle:before {
  content: "\e96a";
}
.icon-pause-circle:before {
  content: "\e96b";
}
.icon-ellipsis:before {
  content: "\e9e9";
}
