.slice-listing-header {
  margin-bottom: 20px;

  &__row {
    @include make-row();
  }

  &__title-area {
    @include make-sm-column(17);
    @include make-md-column(18);
  }

  &__title {
    @include h2;
    margin-top: 0px;
  }

  &__button-area {
    @include make-sm-column(7);
    @include make-md-column(6);
    text-align: right;
    @media (max-width: $screen-xs-max) {
      text-align: left;
    }

  }
}

.namouflage {
  .slice-listing-header {
    &__title {
      color: $color-nam-white;
    }
  }
}

