@mixin container() {
  @include container-fixed;

  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}

@mixin xs-padding {
  @media (max-width: $screen-xs-max) {
    margin-left: 15px;
    margin-right: 15px;
  }
}
