.pagination-block {
  @include sans-serif-body;
  display: table;
  width: 100%;
  padding: 0 10px;

  .pagination {
    float: right;
    margin-bottom: 30px;
    >li {

      margin-bottom: 10px;
      text-transform: uppercase;

      &:last-child>a,
      &:first-child>a {
        border-radius: 2px;
      }
      &.active {
        a {
          background-color: rgba(51, 51, 51, 0.5);
          border: 1px solid $color-nam-white;
          color: $color-nam-white;
        }
      }
      &.disabled {
        a {
          color: rgba(255, 255, 255, 0.4);
          border: 1px solid rgba(255, 255, 255, 0.4);
          &:hover,
          &:focus,
          &.focus {
            background-color: rgba(51, 51, 51, 0.2);
            border: 1px solid rgba(255, 255, 255, 0.4);
            text-decoration: none;
            color: rgba(255, 255, 255, 0.4);
          }
        }
      }
      >a {
        margin-left: 5px;
        border-radius: 2px;

        margin-bottom: 5px;

        @include button-variant($color-nam-white, rgba(51, 51, 51, 0.2), $color-nam-white);
        background-color: rgba(51, 51, 51, 0.2);

        letter-spacing: 0.5px;

        &:hover,
        &:focus,
        &.focus {
          background-color: rgba(51, 51, 51, 0.5);
          border: 1px solid $color-nam-white;
          text-decoration: none;
        }

        .next-icon {
          font-size: 80%;
          margin-left: 2px;
        }
        .prev-icon {
          font-size: 80%;
          margin-right: 2px;
        }
      }
    }
  }
}
