.hero-content {
  @include make-md-column(20);
  @include make-md-column-offset(2);
  @include make-lg-column(16);
  @include make-lg-column-offset(4);

  &.listing {
    @include make-md-column(24);
    @include make-md-column-offset(0);
    @include make-lg-column(24);
    @include make-lg-column-offset(0);
  }

  color: $color-nam-white;

  &__title {
    @include h1;
  }

  &__subtitle {
    @include font-akkurat-pro-regular;
  }

  &__teaser {
    @include sans-serif-body-large;
  }
}

.section-nav {
  margin-top: 40px;
  margin-bottom: -20px;

  &__title {
    @include h4;
  }
  &__list {
    list-style: none;
    position: relative;
    margin-bottom: 0;

    &:before {
      content: '';
      border-left: solid 1px white;
      position: absolute;
      left: 20px;
      top: 10px;

      bottom: -10px;

      @media (min-width: $screen-sm-min) {
        bottom: -30px;
      }

    }

  }
  &__item {
    @include font-akkurat-pro-bold;
    font-size: 16px;
    margin-bottom: 13px;
    position: relative;

    a {
      color: $color-nam-white;
      text-transform: uppercase;
      &.active {
        &:hover,
        &:focus {
          text-decoration: none;
        }

      }
    }

    &:before {
      font-family: 'Linearicons';
      content: "\e901";
      position: absolute;
      left: -26px;
      font-size: 12px;
      top: 0;
    }

    &.active {
      a {
        font-size: 22px;
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
      &.section-nav__item:before {
        font-size: 20px;
        left: -30px;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

  }
}

.hero-content {
  &.side-col {
    @include make-md-column(24);
    @include make-md-column-offset(0);
    @include make-lg-column(20);
    @include make-lg-column-offset(0);
    }
}
