// Fonts
$font-path : '../fonts/';

@mixin font-akkurat-pro-regular{
  font-family: "Akkurat-Pro-Regular", sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-akkurat-pro-bold{
  font-family: "Akkurat-Pro-Bold", sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-akkurat-pro-italic{
  font-family: "Akkurat-Pro-Italic", sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-akkurat-pro-light-italic{
  font-family: "Akkurat-Pro-Lightitalic", sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-akkurat-pro-light{
  font-family: "Akkurat-Pro-Light", sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-akkurat-pro-bold-italic{
  font-family: "Akkurat-Pro-Bolditalic", sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-aleoregular {
  font-family: 'aleoregular', sans-serif;
  font-weight: normal;
  font-style: normal;
}
