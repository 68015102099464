.paragraph-text-1col {
  @include make-md-column(20);
  @include make-md-column-offset(2);
  @include make-lg-column(16);
  @include make-lg-column-offset(4);

  .wysiwyg p:last-of-type {
    margin-bottom: 0;
    @media (min-width: $screen-md-min) {
      padding-bottom: 0;
    }
  }

  &.cta {
    @include accent-colour($applyTo: background);
    color: $color-nam-white;

    padding-bottom: 0;

    .wysiwyg {
      padding: 20px;

      p {
        @include sans-serif-body-large;
      }

    }
  }
}

//styling for paragraph use on 2 col templates -event / resource etc
.twocol-template {
  .paragraph-text-1col {
    @include make-sm-column(24);
    @include make-sm-column-offset(0);

    @media (max-width: $screen-xs-max) {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}
