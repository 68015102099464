.paragraph-text-media {
  @include make-sm-column(24);

  margin-bottom: 70px;
  @media (max-width: $screen-xs-max) {
    margin-bottom: 0;
  }

  &__row {
    @include make-row();
  }

  &.media-left {
    .paragraph-text-media {
      &__left-col {
        @include make-sm-column(12);

        @media (min-width: $screen-sm-min) {
          padding-right: 15px;
        }

        @media (max-width: $screen-xs-max) {
          margin-bottom: 20px;
        }

      }
      &__right-col {
        @include make-sm-column(12);
        @include make-md-column(10);
        @include make-lg-column(8);

        @media (min-width: $screen-sm-min) {
          padding-left: 15px;
        }
      }
    }
  }

  &.media-right {
    .paragraph-text-media {
      &__left-col {
        @include make-sm-column(12);
        @include make-md-column(10);
        @include make-md-column-offset(2);
        @include make-lg-column(8);
        @include make-lg-column-offset(4);

        @media (min-width: $screen-sm-min) {
        padding-right: 15px;
        }

        @media (max-width: $screen-xs-max) {
          margin-bottom: 15px;
        }
      }
      &__right-col {
        @include make-sm-column(12);

        @media (min-width: $screen-sm-min) {
          padding-left: 15px;
        }
      }
    }
  }

}

//styling for paragraph use on 2 col templates -event / resource etc
.twocol-template {
  .paragraph-text-media {
    @include make-sm-column(24);
    @include make-sm-column-offset(0);

    @media (max-width: $screen-xs-max) {
      margin-left: 15px;
      margin-right: 15px;
    }

    &.media-left {
      .paragraph-text-media {
        &__left-col {
          @include make-sm-column(24);
          @include make-md-column(12);

          @media (min-width: $screen-md-min) {
            padding-right: 15px;
          }

          @media (max-width: $screen-sm-max) {
            margin-bottom: 20px;
          }
        }

        &__right-col {
          @include make-sm-column(24);
          @include make-md-column(12);

          @media (min-width: $screen-md-min) {
            padding-left: 15px;
          }
        }
      }
    }

    &.media-right {
      .paragraph-text-media {
        &__left-col {
          @include make-md-column(12);
        }
        &__right-col {
          @include make-md-column(12);

        }
      }
    }

  }
}

