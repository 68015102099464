.wysiwyg{
  *{
    // remove the top margin within a wysiwyg area
    // intended for headings with top and bottom margin
    &:first-child{
      margin-top: 0;
    }
  }
  p {
    @include p;
  }

  h1 {
    @include h1;
  }
  h2 {
    @include h2;
  }
  h3 {
    @include h3;
  }
  h4 {
    @include h4;
  }
  h5 {
    @include h5;
  }
  h6 {
    @include h6;
  }
  small {
    @include small-text;
  }
  b {
    @include font-akkurat-pro-bold;
  }
  //table {
  //  width: 100%;
  //  border-bottom: 1px solid #979797;
  //  padding-bottom: 1rem;
  //  margin-bottom: $base-padding;
  //}
  //th,
  //td {
  //  border-bottom: 0px solid #e1e1e1;
  //  padding: 12px 15px;
  //  text-align: left;
  //  &:last-child {
  //    padding-right: 0;
  //  }
  //}
  //td {
  //  border-right: 1px solid #979797;
  //  border-left: 1px solid #979797;
  //}
  //thead {
  //  @include font-gotham-bold;
  //  text-transform: uppercase;
//
  //  border: 1px solid #979797;
  //  border-bottom: 0px solid #979797;
  //  th {
  //    border-right: 1px solid #979797;
  //    border-left: 1px solid #979797;
  //  }
  //}
  //tbody {
  //  tr:nth-child(even) {
  //    background: #FFF;
  //  }
  //  tr:nth-child(odd) {
  //    background: #EAEAEA;
  //  }
  //}

  .button{

  }
}
