.hero-story {
  @include make-md-column(20);
  @include make-md-column-offset(2);
  @include make-lg-column(16);
  @include make-lg-column-offset(4);

  color: $color-nam-white;

  &__subtitle {
    @include font-akkurat-pro-regular;
  }

  &__title {
    @include h1;
  }

  &__teaser {
    @include sans-serif-body-large;
  }
}
