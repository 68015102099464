.footer {

  background-color: $color-nam-white;
  @include sans-serif-body;

  &__container {
    @include container();

    @media (max-width: $screen-xs-max) {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  &__row {
    @include make-row();
  }

  &__list-col {
    @include make-sm-column(6);
    @include make-md-column(5);
  }
  &__list-col:last-child {
    .nav-list {
      margin-bottom: 0px;
    }
  }

  .nav-list {
    list-style: none;
    padding: 0;
  }

  &__primary {

    margin-top: 30px;

    .nav-list {
      @media (max-width: $screen-xs-max) {
        margin-bottom: 40px;
      }

      &__title {
        @include font-akkurat-pro-bold;
        font-size: 16px;
        margin-bottom: 10px;
        @media (max-width: $screen-xs-max) {
          margin-bottom: 20px;
        }
      }

      &__item {
        margin-bottom: 10px;
        @media (max-width: $screen-xs-max) {
          margin-bottom: 22px;
        }
        a {
          color: $color-nam-base-black;
        }

      }

    }
  }

  &__secondary {
    margin-bottom: 20px;
    font-size: 15px;
  }

  &__secondary-nav {
    @include make-sm-column(24);
    margin-bottom: 10px;
    @media (max-width: $screen-xs-max) {
      margin-bottom: 20px;
    }

    .nav-list {
      &__item {
        display: inline-block;
        margin-right: 10px;
//        padding-right: 15px;
//        border-right: 1px solid $color-nam-mid-grey;
        a {
          color: $color-nam-base-black;
        }
      }
      .divider {
        display: inline-block;
        margin-right: 10px;
        color: $color-nam-mid-grey;
      }
    }
  }

  &__small-copy {
    @include xs-text;

    color: $color-nam-dark-grey;
  }

}
