// postcss changes all `pixel` values to `rem` so we can set all type
//  properties to scale by modifying the `<html>` font size
html{
  font-size: 100%;

//  @media (max-width: $screen-xs-max){
//    font-size: 15Px;
//  }
//  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
//    font-size: 15Px;
//  }

}

body{
  @include font-aleoregular;
  font-size: 18px;
  color: $color-nam-base-black;
  letter-spacing: 0px;
  line-height: 28px;
}
