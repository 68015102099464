.item-info-feature {
  &__label {
    @include item-label;
  }
  &__subtitle {
    @include subtitle;
  }
  &__date {
    @include subtitle;
    margin-bottom: 0;
    font-size: 15px;
  }
  &__price {
    @extend .item-info-feature__date;
    margin-top: 0;
  }
  &__title {
    @include h3;
    color: $color-nam-base-black;
    margin-bottom: 0;
    margin-top: 10px;
  }

  &__teaser {
    @include sans-serif-body;
    margin-top: 0.5rem;
    color: $color-nam-base-black;
  }

  &__button {
    @include  button-variant(red, orange, pink);
  }

}

.item-info-feature--listing {
  .item-info-feature {
    &__title {
      @include h4;
      margin-bottom: 10px;
      margin-top: 13px;
      @media (min-width: $screen-md-min) {
        //margin-bottom: 5px;
      }
    }
    &__subtitle {
      margin-bottom: 0;
    }
  }
}
