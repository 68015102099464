$vars_accent_colours: (
        primary-green: (#3c9700), // Normandy Green
        sea-blue: (#13a1ac), // Sea
        light-blue: (#3C5DA8), // Artillery
        pink: (#E73D51), // Medic
        dark-purple: (#2F1C45), // Union
        orange: (#DD5F15), // Beans
        dark-blue: (#00303B), // Midnight
        red: (#D0122D), // Poppy
        dark-green: (#414827), // Khaki green
        bright-pink: (#E72176), // Flare
        brown: (#4c3f2a), // Khaki brown
);

@mixin namouflage-bg-image {
  @each $item, $colour in $vars_accent_colours {
    .hero-feature--#{$item} &{
      background: url('../img/namouflage--#{$item}.jpg') !important;
    }
  }
}

.hero-feature {
  &:after {
    //clearfix
    content: " "; /* Older browser do not support empty content */
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  background: $color-nam-off-white;
  color: $color-nam-white;

  &__container {
    @include container();
    @include xs-padding;

    @media (min-width: $screen-sm-min) {
      position: relative;
      height: 100%;
    }
  }

  &__row {
    @include make-row();
  }

  &__xs-image {
    @media (min-width: $screen-sm-min) {
      display: none;
    }
  }

  &__namouflage {
    position: absolute;
    width: 100%;
    @media (min-width: $screen-sm-min) {
      left: -30px;
    }
    @media (min-width: $screen-md-min) {
      left: -70px;
    }
    @media (min-width: $screen-lg-min) {
      left: -160px;
    }
    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }
  &__xs-namouflage {
    position: absolute;
    width: 100%;
    @media (min-width: $screen-sm-min) {
      display: none;
    }
  }

  &__bg-padding {

    @media (max-width: $screen-xs-max) {
      background: none !important;
    }

    @media (min-width: $screen-xs-min) {
      @include namouflage-bg-image();
      width: 100%;
      height: auto;
    }

    @media (min-width: $screen-sm-min) {
      background: url('../img/slice-bg-2-1.jpg');
      background-size: cover;
      background-position: center center;

      width: 100%;
      height: 0;
      padding-bottom: 50%;
    }
  }

  &__bg-image {

    background-size: cover;
    max-width: 100%;

    @media (max-width: $screen-xs-max) {
      @include namouflage-bg-image();
      background-size: cover !important;
    }

    @media (min-width: $screen-sm-min) {
      background-size: cover;

      width: 100vw;
      height: 50vw;
    }

  }

  &__content-container {
    @include make-sm-column(14);
    @include make-md-column(14);
    @include make-md-column-offset(0);
    @include make-lg-column(12);
    @include make-lg-column-offset(0);

    padding-top: 10px;

    padding-bottom: 30px;

    @media (min-width: $screen-md-min) {
      padding-top: 30px;
    }
    @media (min-width: $screen-lg-min) {
      padding-top: 50px;
    }
  }

  &__subtitle {
    @include sans-serif-body-large;
    @media (max-width: $screen-sm-max) {
      margin-bottom: 12px;
    }
  }

  &__title {
    @include h1;
    @media (max-width: $screen-sm-max) {
      margin-bottom: 12px;
      margin-top: 8px;
    }
  }

  &__teaser {
    @include sans-serif-body-large;
    margin-bottom: 25px;
    @media (max-width: $screen-xs-max) {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }

}

.hero-feature {
  &__bg-image {
    @media (max-width: $screen-xs-max) {
      @include namouflage-bg-image();
      background-size: cover;
    }
  }
}
