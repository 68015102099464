.social-sharing-toggle {
  background: $color-nam-light-grey;
  padding: .7rem .92rem;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  .icon-share2 {
    font-size: 30px;
    padding: 0;
  }
}

.social-container {
  position: fixed;
  top: -50px;
  right: 100px;

  display: none;

  @media (min-width: $screen-md-min) {
    display: block;
  }

  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;

  &.in-view {
    top: 0;
  }
}

.social-sharing {
  position: fixed;
  top: 3.6rem;

  background: $color-nam-light-grey;

  &__link {
    display: block;
    text-decoration: none;
    color: $color-nam-white;
    margin: 0.4em;
  }

  &__icon {
    display: block;
    img {
      height: 1.1rem;
      width: 1.1rem;
    }
  }
  &__button {
    border-radius: 5px;
    transition: 25ms ease-out;
    padding: 0.75em;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    line-height: 0;

    span {
      padding-left: 0.4em;
    }

    &--twitter {
      @extend .social-sharing__button;
      background-color: #55acee;
      &:hover {
        background-color: #2795e9
      }
    }
    &--facebook {
      @extend .social-sharing__button;
      background-color: #3b5998;
      &:hover {
        background-color: #2d4373;
      }
    }
    &--email {
      @extend .social-sharing__button;
      background-color: #777;
      &:hover {
        background-color: #5e5e5e;
      }
    }
  }
}
