.twocol-template {
  @include container;

  margin-bottom: 30px;

  &__row {
    @include make-row();
  }

  &__side {
    @include make-sm-column(8);
    @include make-md-column(7);
  }

  &__main {
    @include make-sm-column(15);
    @include make-sm-column-offset(1);
    @include make-md-column(16);
    @include make-md-column-offset(1);

    padding-top: 20px;
  }
}

