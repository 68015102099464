@mixin message($background, $icon) {
  background: $background;
  background-image: none;

  padding: 30px 20px 30px 35px;
  color: $color-nam-white;
  border: 0;

  .messages__content-wrapper {
    &:before {
      float: left;
      font-family: Linearicons !important;
      speak: none;
      font-style: normal;
      font-size: 30px;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;

      content: $icon;

      vertical-align: bottom;
      padding-right: 10px;
      @media (max-width: $screen-xs-max) {
        font-size: 20px;
        padding-right: 5px;
      }
    }
  }
}

.messages {
  background: no-repeat 10px 17px;

  @include font-akkurat-pro-bold;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 20px;

  @media (max-width: $screen-xs-max) {
    font-size: 16px;
    line-height: 22px;
    padding: 15px 0 15px 0;
  }

  &__container {
    @include container();
    @include xs-padding;
  }

  &.messages--status {
    @include message($background: $color-nam-primary-green, $icon: "\e959");
  }
  &.messages--warning {
    @include message($background: $color-nam-orange, $icon: "\e955");
  }
  &.messages--error {
    @include message($background: $color-nam-red, $icon: "\e95a");
  }
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  word-wrap: normal;
}
