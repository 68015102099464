body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin p {
  padding-bottom: 1rem;
  @include font-aleoregular;
  font-size: 18px;
  line-height: 28px;
  @media (max-width: $screen-xs-max) {
    padding-bottom: 0.2rem;
  }
}

@mixin heading-shared-styles {
  @include font-akkurat-pro-bold;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
@mixin h1 {
  @include heading-shared-styles;
  font-size: 36px;
  letter-spacing: -0.2px;
  line-height: 38px;
  @media (min-width: $screen-sm-min) {
    font-size: 42px;
    line-height: 46px;
    letter-spacing: -0.3px;
  }
  @media (min-width: $screen-md-min) {
    font-size: 50px;
    line-height: 54px;
  }
  @media (min-width: $screen-lg-min) {
    font-size: 58px;
    line-height: 62px;
  }
}
@mixin h2 {
  @include heading-shared-styles;
  font-size: 30px;
  letter-spacing: -0.3px;
  line-height: 34px;
  @media (min-width: $screen-sm-min) {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -0.3px;
  }
  @media (min-width: $screen-md-min) {
    font-size: 40px;
    line-height: 44px;
    letter-spacing: -0.4px;
  }
}
@mixin h3 {
  @include heading-shared-styles;
  font-size: 24px;
  letter-spacing: -0.1px;
  line-height: 28px;
  @media (min-width: $screen-sm-min) {
    font-size: 30px;
    line-height: 34px;
  }
  @media (min-width: $screen-md-min) {
    font-size: 32px;
    line-height: 38px;
  }
}
@mixin h4 {
  @include heading-shared-styles;
  font-size: 22px;
  letter-spacing: -0.1px;
  line-height: 28px;
  @media (min-width: $screen-sm-min) {
    font-size: 24px;
    line-height: 28px;
  }
  @media (min-width: $screen-md-min) {
    font-size: 26px;
    line-height: 30px;
  }
}
@mixin h5 {
  @include heading-shared-styles;
  font-size: 20px;
  line-height: 24px;
  @media (min-width: $screen-sm-min) {
    font-size: 22px;
    letter-spacing: 0;
    line-height: 26px;
  }
}
@mixin h6 {
  @include heading-shared-styles;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 21px;
  @media (min-width: $screen-sm-min) {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
  }
}

@mixin sans-serif-body {
  @include font-akkurat-pro-regular;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 23px;
  @media (min-width: $screen-sm-min) {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 23px;
  }
  @media (min-width: $screen-md-min) {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 23px;
  }
  @media (min-width: $screen-lg-min) {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
  }
}

@mixin sans-serif-body-large {
  @include font-akkurat-pro-regular;
  font-size: 19px;
  letter-spacing: 0;
  line-height: 22px;

  @media (min-width: $screen-md-min) {
    font-size: 19px;
    letter-spacing: 0;
    line-height: 28px;
  }
}

@mixin subtitle {
  @include font-akkurat-pro-regular;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
  color: $color-nam-dark-grey;
  margin-bottom: 10px;
  @media (min-width: $screen-md-min) {
    font-size: 17px;
    letter-spacing: 0;
    line-height: 23px;
  }
}

@mixin small-text {
  font-size: 15px;
  letter-spacing: 0;
  line-height: 16px;
}

@mixin xs-text {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}

@mixin arrow-link {
  &:after {

    font-family: 'Linearicons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    content: "\e902";
    margin-left: 6px;
    font-size: 50%;
    vertical-align: middle;
  }
}
