.header {

  padding-top: 20px;
  padding-bottom: 20px;

  background-color: $color-nam-white;

  @media (max-width: $screen-xs-max) {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  &__container {
    @include container();
    @include xs-padding;

    //height: 80px;

    position: relative;

    @media (max-width: $screen-sm-max) {
      //height: 65px;
    }

    @media (max-width: $screen-xs-max) {
      //height: 45px;

      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  &__nam-brand {
    display: block;
    position: absolute;
  }

  &__nam-logo {
    height: 80px;

    @media (max-width: $screen-sm-max) {
      height: 65px;
    }

    @media (max-width: $screen-xs-max) {
      height: 45px;
    }


  }

}

.visit-info {

  @media (max-width: $screen-xs-max) {
    display: none;
  }

  &:after {
    //clearfix
    content: " "; /* Older browser do not support empty content */
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @include font-akkurat-pro-regular;
  font-size: 15px;
  color: $color-nam-dark-grey;

  &__list {
    list-style: none;

    float: right;
    margin-top: 0;

  }

  &__item {
    display: inline-block;
    margin-left: 20px;
  }

  &__link {
    color: $color-nam-dark-grey;
    text-decoration: none;
    &:hover,
    &:focus {
      color: $color-nam-dark-grey;
      text-decoration: underline;
    }
  }

  &__icon {
    padding-right: 5px;
    font-size: 20px;
    &.icon-map-marker {
      margin-right: -4px;
    }
    &.icon-ticket {
      display: inherit;
      -ms-transform: rotate(126deg); /* IE 9 */
      -webkit-transform: rotate(126deg); /* Chrome, Safari, Opera */
      transform: rotate(126deg);
    }
  }

  .icon-clock3 {
    //padding-right: 5px;
  }
}

.visit-info-mobile {

  &__container {
    @include container();
    @include xs-padding;
  }

  @media (min-width: $screen-sm-min) {
    display: none;
  }

  background: $color-nam-light-grey;

  padding-top: 4px;
  padding-bottom: 3px;

  @include font-akkurat-pro-regular;
  font-size: 13px;
  color: $color-nam-dark-grey;

  &__list {
    list-style: none;

    float: left;
    margin: 0;
    padding-left: 0;

  }

  &__item {
    display: inline-block;
    margin-left: 0px;
    margin-right: 10px;
  }

  &__icon {
    padding-right: 4px;
    &.icon-ticket {
      display: inherit;
      -ms-transform: rotate(126deg); /* IE 9 */
      -webkit-transform: rotate(126deg); /* Chrome, Safari, Opera */
      transform: rotate(126deg);
    }
  }

  &__link {
    color: $color-nam-dark-grey;
    text-decoration: none;
    &:hover,
    &:focus {
      color: $color-nam-dark-grey;
      text-decoration: underline;
    }
  }

}

.navbar {
  min-height: 20px;
  border-radius: 0;
}

.navbar-nav {

  @media (max-width: $screen-xs-max) {
    margin: 7.5px 2px;
  }

  @media (min-width: $screen-sm-min) {
    margin: -8px;
  }
}

.navbar-default {
  background-color: $color-nam-white;
  border: none;

  margin-bottom: 0;

  @include font-akkurat-pro-bold;
  text-transform: uppercase;
  font-size: 14px;
  color: $color-nam-base-black;
  letter-spacing: 0.3px;

  @media (min-width: $screen-sm-min) {
    margin-top: 2px;

  }

  @media (min-width: $screen-md-min) {
    margin-top: 16px;
    font-size: 16px;
  }
  @media (min-width: $screen-lg-min) {
  }

  .navbar-collapse,
  .navbar-form {
    border-color: $color-nam-white;
  }

  .navbar-nav {
    &>li>a {

      display: inherit;
      padding-top: 12px;
      padding-bottom: 12px;

      padding-left: 0;

      @media (min-width: $screen-sm-min) {
        padding-left: 15px;
        padding-right: 0px;

        padding-bottom: 0;
      }

      @media (min-width: $screen-md-min) {
        padding-left: 22px;
        padding-right: 0px;
      }

      color: $color-nam-base-black;

      &:hover,
      &:focus {
        color: $color-nam-primary-green;
      }
    }

    .divider {
      margin-top: 7px;
      padding-left: 15px;
      padding-right: 15px;

      color: $color-nam-mid-grey;

      @media (max-width: $screen-xs-max) {
        display: none;
      }

      @media (min-width: $screen-sm-min) {
        padding-left: 15px;
        padding-right: 0px;
      }
      @media (min-width: $screen-md-min) {
        padding-left: 22px;
        padding-right: 0px;
      }
    }
  }

  .navbar-toggle {
    background-color: $color-nam-primary-green;
    border-color: $color-nam-primary-green;
    border-radius: 2px;

    padding: 13px 10px;
    margin-top: 2px;
    margin-bottom: 1px;


    &:visited {
      background-color: $color-nam-primary-green;
      border-color: $color-nam-primary-green;
    }

    &:focus,
    &:hover {
      border-color: darken( $color-nam-primary-green, 5% );
      background-color: darken( $color-nam-primary-green, 5% );
    }

    .icon-bar {
      background-color: $color-nam-white;
    }
  }

}


.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover {
  color: $color-nam-primary-green;
  background-color: #ffffff;
}
