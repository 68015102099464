.paragraph-blockquote {
  @include make-sm-column(20);
  @include make-sm-column-offset(2);
  @include make-md-column(16);
  @include make-md-column-offset(4);

  @include sans-serif-body-large;
  text-align: center;
  @include accent-colour($applyTo: text);

  &__quote {
    margin-left: 0;
    margin-right: 0;
  }

  &__hr {
    width: 50px;
    @include accent-colour($applyTo: border);
  }

  &__cite {
    @include sans-serif-body;
  }

  .slice.accent-colour--inherit & {
    color: $color-nam-white;
    &__hr {
      border-top: solid 1px $color-nam-white;
    }
  }

}

blockquote.paragraph-blockquote__quote {
  margin-left: 0px;
  margin-right: 0px;
}

