.card-multi-item-secondary {
  @include make-sm-column(11);
  @include make-sm-column-offset(2);
  @include make-lg-column(10);
  @include make-lg-column-offset(14);

  color: $color-nam-base-black;
  background-color: $color-nam-off-white;
  padding: 0px 0px 15px 0px;

  @media (min-width: $screen-sm-min) {
    bottom: 0;
    right: 0;
    padding: 0px 15px 15px 15px;
    margin-top: 20px;
  }
  @media (min-width: $screen-md-min) {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0px 15px 15px 15px;
    margin-top: 0px;
  }

}

.card-multi-item-secondary {
  .item-info {
    border-top: 1px solid $color-nam-mid-grey;
  }
}

//card styling for sm screens only

.card-multi-item-secondary-sm-only {
  display: none;
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    display: block;
  }

  @include make-row();

  .item-info {
    @include make-sm-column(12);
  }

  background-color: $color-nam-off-white;
  padding: 0px 0px 15px 0px;

  @media (min-width: $screen-sm-min) {
    position: absolute;
    bottom: 0;
    right: 0;

    padding: 0px 15px 15px 15px;
  }


}
