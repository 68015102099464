.form-block {
    &__container {
      @include container;
      @include xs-padding;
    }
    &__row {
      @include make-row();
    }
}

.webform-submission-contact-form {
  @include make-md-column(20);
  @include make-md-column-offset(2);
  @include make-lg-column(16);
  @include make-lg-column-offset(4);

  margin: 30px 0px;
}

.js-form-item.form-item {
  margin-bottom: 20px;
}
