.paragraph-text-2col {
  @include make-md-column(20);
  @include make-md-column-offset(2);
  @include make-lg-column(16);
  @include make-lg-column-offset(4);

  margin-bottom: 10px;

  &__row {
    @include make-row();
  }

  &__left-col {
    @include make-sm-column(12);

    .wysiwyg p:last-of-type {
      @media (min-width: $screen-sm-min) {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    @media (min-width: $screen-sm-min) {
      padding-right: 15px;
    }
  }
  &__right-col {
    @include make-sm-column(12);

    .wysiwyg p:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    @media (min-width: $screen-sm-min) {
      padding-left: 15px;
    }
  }

}

//styling for paragraph use on 2 col templates -event / resource etc
.twocol-template {
  .paragraph-text-2col {
    @include make-sm-column(24);
    @include make-sm-column-offset(0);

    @media (max-width: $screen-xs-max) {
      margin-left: 15px;
      margin-right: 15px;
    }

    &__left-col {
      @include make-sm-column(24);
      @include make-md-column(12);

      @media (min-width: $screen-sm-min) {
        padding-right: 15px;
      }
    }

    &__right-col {
      @include make-sm-column(24);
      @include make-md-column(12);

      @media (min-width: $screen-sm-min) {
        padding-left: 15px;
      }
    }
  }
}



