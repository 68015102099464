.meta-data {

  background-color: $color-nam-off-white;

  padding: 0px 15px 5px 15px;

  @include sans-serif-body;

  @media (max-width: $screen-xs-max) {
    margin-left: 15px;
    margin-right: 15px;
  }

  &__label {
    @include item-label;
  }

  &__heading {
    @include font-akkurat-pro-bold;
    font-size: 18px;

    margin-bottom: 5px;
  }

  &__content {
    margin-top: 5px;
    margin-bottom: 25px;

    .address {
      white-space: pre-line;
    }

    small {
      @include small-text;
    }
  }

  &__icon {
    margin-right: 5px;
  }

  &__theme-content {
    margin-top: 5px;
    margin-bottom: 25px;
  }

  &__button-wrapper {
    margin: 25px 0px;
  }

}

.theme-tag {
  display: inline-block;

  padding: 8px 10px;
  @include accent-colour($applyTo: tag);

  .page-accent-colour--pink &{
    background-color: lighten( #E73D51, 30% );
  }

  @include font-akkurat-pro-bold;
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 1;
  text-transform: uppercase;
  color: $color-nam-base-black;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;

  @media (max-width: $screen-xs-max) {
    margin-bottom: 10px;
  }
}
