.item-info {
  &__label {
    @include item-label;
    margin-top: -1px;
  }
  &__subtitle {
    @include subtitle;
    margin-bottom: 0px;
  }
  &__title {
    @include h4;
    margin-top: 15px;
    margin-bottom: 12px;
    @media (max-width: $screen-xs-max) {
      margin-top: 8px;
      margin-bottom: 11px;
    }
  }
  &__title-link {
    color: $color-nam-primary-green;
    &:hover,
    &:focus {
      color: darken($color-nam-primary-green, 10%);
      text-decoration: none;
    }
    @include arrow-link;
  }

  &__teaser {
    @include sans-serif-body;
    margin-top: 0.5rem;
  }

}



