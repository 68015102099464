$vars_accent_colours: (
  primary-green: (#3c9700), // Normandy Green
  sea-blue: (#13a1ac), // Sea
  light-blue: (#3C5DA8), // Artillery
  pink: (#E73D51), // Medic
  dark-purple: (#2F1C45), // Union
  orange: (#DD5F15), // Beans
  dark-blue: (#00303B), // Midnight
  red: (#D0122D), // Poppy
  dark-green: (#414827), // Khaki green
  bright-pink: (#E72176), // Flare
  brown: (#4c3f2a), // Khaki brown
);

@mixin accent-colour($applyTo) {

  @each $item, $colour in $vars_accent_colours {

    @if $applyTo == border {
      .page-accent-colour--#{$item} &{
        border-top: solid 1px $colour;
      }
    }

    @else if $applyTo == text {
      .page-accent-colour--#{$item} &{
        color: $colour;
      }
    }

    @else if $applyTo == background {
      .page-accent-colour--#{$item} &{
        background-color: $colour;
        color: $color-nam-white;
      }
    }

    @else if $applyTo == tag {
      .page-accent-colour--#{$item} &{
        background-color: lighten( $colour, 43% );
      }
    }

    @else if $applyTo == gradient {
      .page-accent-colour--#{$item} &{
        background: linear-gradient(to bottom, $colour 60%, $color-nam-white 100%);
      }
    }

  }

}

// The default page accent colour can be overridden on a per
// element basis by using the class below on the element.
@each $item, $colour in $vars_accent_colours {
  .accent-colour--#{$item} {
    background-color: $colour !important;
  }
}

