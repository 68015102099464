// Primary colours
$color-nam-base-black:  #333333; // Text black
$color-nam-primary-green:  #3c9700; // Normandy green
$color-nam-white:   #ffffff; // White
$color-nam-true-black:   #000000; // True black
$color-nam-dark-grey:   #666666; // Dark grey
$color-nam-mid-grey:   #E1E1E1; // Mid grey
$color-nam-light-grey:   #F5F5F5; // Light grey
$color-nam-off-white:   #FCFCFC; // Off white
$color-nam-beige:   #E5D6CC; // Light beige

// Accent colours
$color-nam-sea-blue:   #13a1ac; // Sea
$color-nam-light-blue:   #3C5DA8; // Artillery
$color-nam-pink:   #E73D51; // Medic
$color-nam-dark-purple:   #2F1C45; // Union
$color-nam-orange:   #DD5F15; // Beans
$color-nam-dark-blue:   #00303B; // Midnight
$color-nam-red:   #D0122D; // Poppy
$color-nam-dark-green:   #414827; // Khaki green
$color-nam-bright-pink:   #E72176; // Flare
$color-nam-brown:   #4c3f2a; // Khaki brown

//Social
$color-facebook-blue:   #3B5998; // Sea
$color-twitter-blue:   #1DA1F2; // Sea
$color-youtube-red:   #E52D27; // Sea

