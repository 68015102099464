.card-feature {
  @include make-sm-column(14);
  @include make-md-column(11);
  @include make-lg-column(10);

  background-color: $color-nam-off-white;
  color: $color-nam-base-black;
  padding: 0px 0px 15px 0px;

  margin-bottom: 10px;

  @media (min-width: $screen-sm-min) {
    padding: 0px 15px 15px 15px;
    margin-bottom: 20px;
  }

  @media (min-width: $screen-md-min) {
    margin-bottom: 0px;
  }

  &__label {
    @include item-label;
  }

  &__subtitle {
    @include subtitle;
  }

  &__title {
    @include h2;
    @media (max-width: $screen-xs-max) {
      margin-bottom: 12px;
      margin-top: 8px;
    }
  }

  &__teaser {
    @include sans-serif-body;

    @media (max-width: $screen-xs-max) {
      margin-top: 10px;
    }
  }

}
