.template-listing {

  background: $color-nam-sea-blue;

  background-image: url('../img/nam-sea-blue.jpg');
  background-size: contain;

  padding-top: 30px;
  padding-bottom: 10px;

  &__container {
    @include container();
    @include xs-padding;
  }

}
