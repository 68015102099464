.slice {

  clear: both;

  &:after {
    //clearfix
    content: " "; /* Older browser do not support empty content */
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  background: $color-nam-off-white;

  &.accent-colour--inherit {
    @include accent-colour($applyTo: background);
  }

  &__overlay {
    background: rgba(225,225,225,0.8);
  }

  &__container {
    @include container();
    @include xs-padding;

    @media (min-width: $screen-sm-min) {
      position: relative;
      height: 100%;
    }


  }

  &__row {
    @include make-row();
  }

  &__xs-image {

    @media (min-width: $screen-sm-min) {
      display: none;
    }
  }

  &__bg-padding {

    @media (max-width: $screen-xs-max) {
    background: none !important;
  }

    @media (min-width: $screen-md-min) {
      background-size: cover;
      background-position: center center;

      width: 100%;
      height: 0;
      padding-bottom: 50%;
    }

  }


  &__bg-image {
    max-width: 100%;

    @media (max-width: $screen-xs-max) {
      background: none !important;
    }

    @media (min-width: $screen-sm-min) {
      background-size: cover;
      min-height: 50vw;
    }

    @media (min-width: $screen-md-min) {
      width: 100vw;
      height: 50vw;
    }

  }

  &__content-container {
    padding-top: 30px;
    padding-bottom: 30px;

    @media (min-width: $screen-sm-min) {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  &.bg-v-image {

    @media (min-width: $screen-sm-min) {
      background-image: url('../img/background-test.jpg');
      background-size: cover;
    }

  }

  &.namouflage {
    @include accent-colour($applyTo: background);
    background-image: url('../img/namouflage.png');
    background-size: cover;
    background-position: center center;
    color: $color-nam-white;
  }
  &.namouflage-fixed {
    background-image: url('../img/namouflage--primary-green.jpg');
    background-size: cover;
    background-position: center center;
    color: $color-nam-white;
  }

  &.timeline {
    .slice__content-container {
      position: relative;
    }
  }

  &__timeline-background {
    opacity: 0.1;
    filter: alpha(opacity=30); /* For IE8 and earlier */
    background: linear-gradient(to bottom, $color-nam-bright-pink 60%,$color-nam-white 100%);

    width: 30px;
    height: 92%;
    position: absolute;
    top: 0;
    left: -10px;

    @media (min-width: $screen-sm-min) {
      left: 40%;
      width: 20%;
    }
  }

}

.slice {
  div:last-child {

  }
}

.slice.transparent {
  .slice__row {
    @include make-row();
    & > div:last-of-type {
      margin-bottom: 0;
    }
  }
}

.slice.accent-colour--inherit {
  .slice__row {
    @include make-row();
    & > div:last-of-type {
      .wysiwyg {
        & > p:last-of-type {
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}
