figure {
  position: relative;

  &.caption {
    position: relative;
  }

  .caption-toggle {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-left: 0;

    display: flex;

    @include sans-serif-body;
    font-size: 15px;
    text-align: left;
    color: $color-nam-white;

    @media (max-width: $screen-xs-max) {
      position: relative;
      bottom: 0;
      right: 0px;
      margin-left: 0px;
    }

    &--open {
      .caption-toggle__caption {
        visibility: hidden;
        opacity: 0;
      }
      .caption-toggle__toggle {
        &::before {
          font-family: 'Linearicons' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 0.5;
          content: "\e904";
          vertical-align: middle;
          font-size: 140%;
        }
      }
    }

    &__caption {
      background: rgba(51, 51, 51, 0.8);
      padding: 10px 20px;
      visibility: visible;
      opacity: 1;
      -webkit-transition: visibility 0s, opacity 0.5s linear;
      transition: visibility 0s, opacity 0.5s linear;
      @media (max-width: $screen-xs-max) {
        background: $color-nam-base-black;
        padding: 10px 15px;
      }
    }

    &__toggle {
      background: rgba(51, 51, 51, 0.8);
      padding: 10px 20px;
      @media (max-width: $screen-xs-max) {
        background: $color-nam-base-black;
        display: none; //as position fixed under image hide toggle
      }

      &::before {
        font-family: 'Linearicons' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        content: "\e905";
        vertical-align: middle;
      }
    }

  }
}
