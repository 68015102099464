.filters-block {
  @include make-row();
  margin-bottom: 30px;
  &__tab {
    @include make-sm-column(8);
    @media (max-width: $screen-sm-max) {
      margin-bottom: 10px;
    }
  }
  .btn-group.bootstrap-select {
    .btn {
      background-color: $color-nam-white;
      color: $color-nam-base-black;
      border-color: $color-nam-white;
      padding: 10px 10px 8px;
      text-transform: none;
      font-size: 18px;
      border-radius: 0;
      @media (min-width: $screen-sm-min) {
        padding: 16px 10px 14px;
        font-size: 17px;
      }
      @media (min-width: $screen-md-min) {
        padding: 16px 15px 13px;
        font-size: 18px;
      }
      .caret {
        border-top: 10px dashed;
        border-top: 10px solid;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        right: 18px;
        @media (max-width: $screen-sm-max) {
          border-top: 9px dashed;
          border-top: 9px solid;
          border-right: 7px solid transparent;
          border-left: 7px solid transparent;
          right: 15px;
          margin-top: -3px;
        }
      }
      .icomoon {
        padding-right: 5px;
        @media (max-width: $screen-sm-max) {
          padding-right: 2px;
        }
      }
    }
    .dropdown-menu {
      //margin: -1px 0 0 2px;
      border-radius: 0;
      border: 1px solid $color-nam-white;
      box-shadow: 0 0 0;
      min-width: 98%;
      li {
        a {
          padding: 6px 12px;
          @include font-akkurat-pro-regular;
          font-size: 15px;
          @media (min-width: $screen-md-min) {
            padding: 10px 12px;
          }
        }
      }
    }
  }
  .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100%;
  }
}
