.card-social {

  &__container {
    @include make-md-column(16);
    @include make-md-column-offset(4);


    @media (min-width: $screen-sm-min) {
      margin-top: 20px;
      margin-bottom: 20px
    }

  }

  &__title {
    @include h3;
    text-align: center;
    margin-top: 0px;
  }

  &__quote {
    @include sans-serif-body-large;
    text-align: center;
  }

  &__social-tag {
    @include sans-serif-body-large;

    text-align: center;
    text-decoration: none;
    color: $color-nam-primary-green;

    display: block;

    margin-bottom: 20px;
  }

  &__link-area {
    text-align: center;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    li {
      display: inline-block;
      margin-right: 8px;

      @media (min-width: $screen-sm-min) {
        margin-right: 10px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

  }

  &__list-item {
    img {
      height: 35px;
      padding: 0 7px;

      webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);

      -webkit-transition-duration:.3s;
      transition-duration: .3s;

      &:hover {
        webkit-transform: scale(1.3);
        -ms-transform: scale(1.3);
        transform: scale(1.3);

        -webkit-transition-duration:.3s;
        transition-duration: .3s;
      }

      @media (min-width: $screen-sm-min) {
        height: 40px;
      }
    }

  }

}
