.paragraph-media-2col {
  @include make-sm-column(24);

  margin-bottom: 50px;
  @media (max-width: $screen-xs-max) {
    margin-bottom: 30px;
  }

  &__row {
    @include make-row();
  }

  &__left-col {
    @include make-sm-column(12);

    @media (max-width: $screen-xs-max) {
      margin-bottom: 10px;
    }
  }
  &__right-col {
    @include make-sm-column(12);
  }

}
