/*!
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * nam.ac.uk
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2016
 */

/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The fonts folder(s) should be placed relative to the regular CSS file.
 *
 * You can use either the complete or subsetted fonts:
 * If you don’t require the entire range of characters, you can use the smaller, subsetted webfonts instead.
 * See "Glyphs & Languages" for an overview of the characters in the *_subsetted.html file in the root directory of this package.
 *
 */


/* Complete */

@font-face {
    font-family: "Akkurat-Pro-Regular";
    src:url("#{$font-path}/lineto-akkurat-pro-regular.eot");
    src:url("#{$font-path}/lineto-akkurat-pro-regular.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/lineto-akkurat-pro-regular.woff2") format("woff2"),
        url("#{$font-path}/lineto-akkurat-pro-regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Akkurat-Pro-Bold";
    src:url("#{$font-path}/lineto-akkurat-pro-bold.eot");
    src:url("#{$font-path}/lineto-akkurat-pro-bold.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/lineto-akkurat-pro-bold.woff2") format("woff2"),
        url("#{$font-path}/lineto-akkurat-pro-bold.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Akkurat-Pro-Italic";
    src:url("#{$font-path}/lineto-akkurat-pro-italic.eot");
    src:url("#{$font-path}/lineto-akkurat-pro-italic.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/lineto-akkurat-pro-italic.woff2") format("woff2"),
        url("#{$font-path}/lineto-akkurat-pro-italic.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Akkurat-Pro-Lightitalic";
    src:url("#{$font-path}/lineto-akkurat-pro-lightitalic.eot");
    src:url("#{$font-path}/lineto-akkurat-pro-lightitalic.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/lineto-akkurat-pro-lightitalic.woff2") format("woff2"),
        url("#{$font-path}/lineto-akkurat-pro-lightitalic.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Akkurat-Pro-Light";
    src:url("#{$font-path}/lineto-akkurat-pro-light.eot");
    src:url("#{$font-path}/lineto-akkurat-pro-light.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/lineto-akkurat-pro-light.woff2") format("woff2"),
        url("#{$font-path}/lineto-akkurat-pro-light.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Akkurat-Pro-Bolditalic";
    src:url("#{$font-path}/lineto-akkurat-pro-bolditalic.eot");
    src:url("#{$font-path}/lineto-akkurat-pro-bolditalic.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/lineto-akkurat-pro-bolditalic.woff2") format("woff2"),
        url("#{$font-path}/lineto-akkurat-pro-bolditalic.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
